/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'

import Header from './header'

const Layout = ({ invertHeader, children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Header invertHeader={invertHeader} siteTitle={data.site.siteMetadata.title} />
        <div className="font-serif leading-normal bg-grey-darkest flex flex-col min-h-screen">
          <main className="flex-1">{children}</main>
          <footer className="bg-grey-darkest text-grey py-8 text-sm">
            <div className="container">
              &copy; 2005 - {new Date().getFullYear()} amillionmonkeys ltd. All rights reserved.
              <br />
              Company no. 08488765 | VAT no. 187 4231 91
            </div>
          </footer>
        </div>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  invertHeader: PropTypes.bool,
}

Layout.defaultProps = {
  invertHeader: false,
}

export default Layout
