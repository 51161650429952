import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'

const Logo = require('../svgs/logo.svg')

const Header = ({ siteTitle }) => (
  <header className="font-impact p-4 flex  items-center  absolute z-10 w-full">
    <a href="/" title="Home">
      <Logo className="w-16 h-16 mr-2 md:mr-4" />
    </a>
    <nav aria-label="Primary Navigation" className="flex-1 w-full">
      <ul className="h-full list-reset flex flex-wrap items-center justify-between max-w-xs  primary-navigation  w-full  px-2 text-xl sm:text-2xl md:text-3xl">
        <li>
          <Link activeClassName="active" to="/">
            Home
          </Link>
        </li>
        <li>
          <Link activeClassName="active" to="/about">
            About
          </Link>
        </li>
        <li>
          <Link activeClassName="active" partiallyActive to="/work">
            Work
          </Link>
        </li>
        <li>
          <Link activeClassName="active" to="/contact">
            Contact
          </Link>
        </li>
      </ul>
    </nav>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
